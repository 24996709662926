import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MuiSwitch from "@material-ui/core/Switch";
import MuiTextField from "@material-ui/core/TextField";
import { Form, Formik, useField } from "formik";
import React from "react";
import { t } from "../../../utility/TranslateUtility";
import { AlliMobaModules, iMobaUser, PaperComponent } from "./Common";

export interface AddUserDialogPresentationProps {
  isOpen: boolean;
  handleClose: () => void;
  isUpdate: boolean;
  user: iMobaUser;
  handleSave: (newUser: iMobaUser) => void;
}

const useStyles = makeStyles((theme) => ({
  textField: {
    margin: theme.spacing(1),
    marginLeft: 0,
    marginRight: theme.spacing(10),
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
  },
}));

export const maxEmailLength = 254;

export function AddOrUpdateUserDialogPresentation({
  isOpen,
  isUpdate,
  user,
  handleClose,
  handleSave,
}: AddUserDialogPresentationProps) {
  const classes = useStyles();
  const validate = (values: any): any => {
    const errors: any = {};
    if (!values.email) {
      errors.email = t("forms.isRequired");
    } else if (
      !/^[A-Z0-9._%+-]+('[A-Z0-9._%+-]+)*@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
        values.email
      )
    ) {
      errors.email = t("forms.emailInvalid");
    } else if (values.email.length > maxEmailLength) {
      errors.email = t("forms.emailTooLong");
    }

    // update field validation
    if (isUpdate) {
      if (!values.firstName)
        errors.firstName = t("users.errors.firstNameRequired");
      if (!values.lastName)
        errors.lastName = t("users.errors.lastNameRequired");
      if (!values.phoneNumber)
        errors.phoneNumber = t("users.errors.phoneNumberRequired");
    }

    return errors;
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          {isUpdate ? t("users.titles.updateUser") : t("users.titles.addUser")}
        </DialogTitle>

        <Formik
          initialValues={user}
          onSubmit={handleSave}
          validate={validate}
          enableReinitialize={true}
        >
          {({ isValid, isSubmitting, dirty }) => (
            <Form>
              <DialogContent className={classes.dialogContent}>
                <DialogContentText>
                  {t("users.labels.userInformation")}
                </DialogContentText>
                <TextField
                  label={t("users.labels.email")}
                  name="email"
                  type="email"
                  className={classes.textField}
                  variant="filled"
                  disabled={isUpdate}
                />
                {isUpdate && (
                  <>
                    <TextField
                      label={t("users.labels.firstName")}
                      name="firstName"
                      className={classes.textField}
                      variant="filled"
                    />
                    <TextField
                      label={t("users.labels.lastName")}
                      name="lastName"
                      className={classes.textField}
                      variant="filled"
                    />
                    <TextField
                      label={t("users.labels.phoneNumber")}
                      name="phoneNumber"
                      className={classes.textField}
                      variant="filled"
                    />
                  </>
                )}
                {!isUpdate && (
                  <>
                    <DialogContentText>
                      {t("users.labels.modules")}
                    </DialogContentText>

                    {AlliMobaModules.map((module) => (
                      <FormControlLabel
                        key={module}
                        control={
                          <Switch
                            type="checkbox"
                            name="modules"
                            value={module}
                            color="primary"
                          />
                        }
                        label={t(`users.modules.${module}`)}
                      />
                    ))}
                  </>
                )}
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                  {t("users.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={!isValid || !dirty}
                >
                  {t("users.buttons.save")}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

export function TextField({ ...props }: any) {
  const [field, meta]: any = useField(props);
  const isError =
    meta.touched && meta.error && typeof meta.error !== "undefined";

  const errorAttributes = isError ? { helperText: meta.error } : undefined;

  return (
    <MuiTextField error={isError} {...errorAttributes} {...field} {...props} />
  );
}

function Switch({ ...props }: any) {
  const [field, _]: any = useField(props);

  return <MuiSwitch {...field} {...props} />;
}
